export default {

  apiKey: "AIzaSyCGmJgiGVJwrecdZgDdSpRGw68pF4rzHdc",

  authDomain: "reliance-1xg1.firebaseapp.com",

  projectId: "reliance-1xg1",

  storageBucket: "reliance-1xg1.appspot.com",

  messagingSenderId: "1016225965339",

  appId: "1:1016225965339:web:ac03654069657c38d05fbe"

};
